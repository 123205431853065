import { useEffect, useState } from 'react';
import { usePopup } from 'api/header/usePopup';
import { getPopupImageUrl } from 'helpers/imageUrl';
import { AddPopup } from './AddPopup';
import { t } from '@lingui/macro';

const localStorageItem = 'hidePopup';
const hidePopup = window.sessionStorage.getItem(localStorageItem);

export const Add = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isValidating } = usePopup({ skip: hidePopup });

  const onCloseHandler = () => {
    setIsOpen(false);
    window.sessionStorage.setItem(localStorageItem, 'true');
  };

  useEffect(() => {
    data && !isValidating && !hidePopup && setIsOpen(true);
  }, [data, isValidating]);

  return (
    <AddPopup
      isOpen={isOpen}
      onClose={onCloseHandler}
      bgColor={data?.bgcolour}
      textColor={data?.colour}
      imgUrl={data?.uuid && getPopupImageUrl(data.uuid)}
      title={t`Click anywhere to close`}
    />
  );
};

import { useCallback, useEffect, useMemo } from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { useCurrency } from 'hooks/useCurrency';
import { getDesignsImageUrl } from 'helpers/imageUrl';
import { getCookie } from '../../helpers/cookies';

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_SEGMENT_KEY,
});

function getOptions(user, address) {
  const options = {};
  if (!!user) {
    options.userId = user && user.id;
    options.email = user && user.email;
    options.user_name = user && user.username;
  }
  if (!!address) {
    options.name = address && address.ship_addressee;
    options.phone = address && address.phone_number;
    options.address = {
      city: address && address.ship_city,
      state: address && address.ship_state,
      country: address && address.ship_country,
      postalCode: address && address.ship_postal_code,
    };
  }
  return { context: { traits: options } };
}

function getProperties(properties) {
  properties = properties || {};
  properties.fbc = getCookie('_fbc');
  properties.fbp = getCookie('_fbp');
  return properties;
}

export const useSegment = () => {
  const { pathname } = useLocation();
  const currency = useCurrency();
  const { user, address } = useAuth();

  const track = useCallback(
    (event, properties) => {
      properties = getProperties(properties);
      const options = getOptions(user, address);
      analytics.track(event, properties, options);
    },
    [address, user]
  );

  const productViewAction = useCallback(
    (product) => {
      track('Product Viewed', {
        product_id: product.id.toString(),
        name: product.name,
      });
    },
    [track]
  );

  const addToCartAction = useCallback(
    (product) => {
      track('Product Added', {
        product_id: product.productId.toString(),
        category: product.category,
        name: product.name,
        price: Number(product.price[currency.code]),
        variant: product.variant,
        quantity: Number(product.quantity),
        currency: currency.code,
      });
    },
    [currency.code, track]
  );

  const cartViewedAction = useCallback(
    (items) => {
      track('Cart Viewed', {
        products: mapItemsHelper(items, currency.code),
        currency: currency.code,
      });
    },
    [currency.code, track]
  );

  const identifyOrderAction = useCallback(
    (order) => {
      const properties = {
        email: order.email,
        name: order.ship_addressee,
        phone: order.phone_number,
        address: {
          city: order.ship_city,
          state: order.ship_state,
          country: order.ship_country,
          postalCode: order.ship_postal_code,
        },
      };
      return analytics.identify(user?.id, properties);
    },
    [user]
  );

  const orderCompleteAction = useCallback(
    (order) => {
      identifyOrderAction(order);
      track('Order Completed', {
        order_id: order.id.toString(),
        total: order.total,
        tax: Number(order.tax),
        currency_total: order.total,
        subtotal: Number(order.sub_total),
        shipping: Number(order.shipping_cost),
        coupon: order.discount_code,
        currency: currency.code,
        products: order.items.map((item) => ({
          product_id: item.product_id.toString(),
          name: item.name,
          price: item.unit_price,
          quantity: item.quantity,
          category: item.sizeFull && item.sizeFull.split(' ')[0],
        })),
      });
    },
    [currency.code, identifyOrderAction, track]
  );

  const identify = useCallback(() => {
    if (!user?.id) return;
    const properties = {
      email: user.email,
      userName: user.username,
      userId: user.id,
    };
    if (address) {
      properties.name = address.ship_addressee;
      properties.phone = address.phone_number;
      properties.address = {
        city: address.ship_city,
        state: address.ship_state,
        country: address.ship_country,
        postalCode: address.ship_postal_code,
      };
    }
    return analytics.identify(user.id, properties);
  }, [user, address]);

  const getAnalytics = useCallback(() => {
    return analytics;
  }, []);

  useEffect(() => {
    const properties = getProperties();
    const options = getOptions(user, address);
    analytics.page('', '', properties, options);
  }, [address, pathname, user]);

  useEffect(() => {
    identify();
  }, [identify, user]);

  return useMemo(
    () => ({
      cartViewedAction,
      productViewAction,
      addToCartAction,
      orderCompleteAction,
      getAnalytics,
    }),
    [
      cartViewedAction,
      productViewAction,
      addToCartAction,
      orderCompleteAction,
      getAnalytics,
    ]
  );
};

function mapItemsHelper(items) {
  return items.map((item) => {
    return {
      image_url: getDesignsImageUrl(item.img, 450, 540),
      name: item.name,
      price: Number(item.price),
      product_id: item.id.toString(),
      quantity: item.num,
      size: item.size,
    };
  });
}
